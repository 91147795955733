import {
  Card,
  CardHeader,
  CardBody,
  Typography,
  CardFooter,
} from "@material-tailwind/react";
import {
  MagnifyingGlassIcon,
  EllipsisVerticalIcon,
} from "@heroicons/react/24/outline";
import { Dropdown } from "flowbite-react";
import { useEffect, useState } from "react";
import LoadingDialog from "../common/loading";
import { fetchVendors } from "../../services/vendor";
import "./Vendors.css";
import VendorDeleteDialog from "./VendorDeleteConfirm";
import VendorEditForm from "./VendorEditForm";

const VendorTable = (props) => {
  const [loading, setLoading] = useState(true);
  const [allVendorList, setAllVendorList] = useState([]);
  const [vendorList, setVendorList] = useState([]);
  const [vendorToDeleteName, setVendorToDeleteName] = useState("");
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openEditForm, setOpenEditForm] = useState(false);
  const [vendorToEditData, setVendorToEditData] = useState({});

  const [maximumRows, setMaximumRows] = useState(25);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);

  const fetchTableData = async () => {
    setLoading(true);
    const list = await fetchVendors();
    const copyList = [...list];
    if (list.length != 0) {
      setCurrentPage(1);
      setTotalPages(Math.floor((list.length - 1) / maximumRows + 1));
    } else {
      setCurrentPage(0);
      setTotalPages(0);
    }

    setVendorList(copyList.slice(0, maximumRows));
    setAllVendorList(list);
    setLoading(false);
  };

  useEffect(() => {
    fetchTableData();
  }, [props.refresh]);

  const changeMaxRows = (rows) => {
    const copyList = [...allVendorList];
    if (allVendorList.length == 0) {
      setCurrentPage(0);
      setTotalPages(0);
    } else {
      setCurrentPage(1);
      setTotalPages(Math.floor((allVendorList.length - 1) / rows + 1));
    }
    setVendorList(copyList.splice(0, rows));
    setMaximumRows(rows);
  };

  const handleFirstPage = () => {
    if (allVendorList.length == 0) {
      setCurrentPage(0);
    } else {
      setCurrentPage(1);
    }
    const copyList = [...allVendorList];
    setVendorList(copyList.splice(0, maximumRows));
  };

  const handleLastPage = () => {
    if (allVendorList.length == 0) {
      setCurrentPage(0);
    } else {
      setCurrentPage(totalPages);
    }
    const startIdx = (totalPages - 1) * maximumRows;
    const copyList = [...allVendorList];
    setVendorList(copyList.splice(startIdx, maximumRows));
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      const startIdx = currentPage * maximumRows;
      const copyList = [...allVendorList];
      setVendorList(copyList.splice(startIdx, maximumRows));
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      const startIdx = (currentPage - 2) * maximumRows;
      const copyList = [...allVendorList];
      setVendorList(copyList.splice(startIdx, maximumRows));
      setCurrentPage(currentPage - 1);
    }
  };

  const deleteVendor = (vendorName) => {
    setVendorToDeleteName(vendorName);
    setOpenDeleteDialog(true);
  };

  const editVendor = (vendorData) => {
    setVendorToEditData(vendorData);
    setOpenEditForm(true);
  };

  const TABLE_HEAD = [
    "Vendor's Name",
    "Phone Number",
    "Region",
    "Category",
    "Tags",
    "Creation Date",
    "Actions",
  ];
  return (
    <>
      <Card className="w-full overflow-scroll border-solid border">
        <CardHeader
          floated={false}
          shadow={false}
          className="rounded-xl p-2 m-0 flex justify-between items-center "
        >
          <LoadingDialog
            isOpen={loading}
            closeDialog={() => {
              setLoading(false);
            }}
          />
          <div className="md:w-72 ml-2 w-60 relative">
            <input
              autoComplete="off"
              type="text"
              name="search"
              placeholder="Search Vendors"
              className="pl-12 px-2 w-full border-none bg-gray-100 focus:ring-0 rounded-2xl py-2 text-gray-700 text-xs"
            />
            <MagnifyingGlassIcon
              className="absolute inset-y-0 left-0 pl-3
                    flex items-center  
                    pointer-events-none h-8 w-8 text-gray-400"
            />
          </div>
          <EllipsisVerticalIcon className="h-6 w-6 p-0 m-0" />
        </CardHeader>
        <CardBody className="overflow-x-scroll p-0 m-0 max-h-screen">
          <table className="w-full min-w-max table-auto text-left m-0">
            <thead className="">
              <tr>
                {TABLE_HEAD.map((head) => (
                  <th
                    key={head}
                    className="border-y border-gray-200 bg-gray-50 py-2 px-3"
                  >
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="leading-none text-sm font-roboto font-semibold"
                    >
                      {head}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="overflow-y-scroll">
              {vendorList.length == 0 ? (
                <tr>
                  <td colSpan={7} className="text-center py-2 px-3 border-y">
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal text-sm font-roboto"
                    >
                      You haven't created any vendor yet.
                    </Typography>
                  </td>
                </tr>
              ) : (
                vendorList.map(
                  (
                    {
                      vendorName,
                      vendorPhoneNumber,
                      vendorRegion,
                      activityCategory,
                      activityTags,
                      createdAt,
                    },
                    index
                  ) => {
                    const isOdd = index % 2 == 1;
                    const timestamp = createdAt.toDate();
                    const date = `${timestamp.getDate()}/${
                      timestamp.getMonth() + 1
                    }/${timestamp.getFullYear()}`;
                    return (
                      <tr
                        key={index}
                        className={`border-y ${isOdd ? "bg-gray-50" : ""} `}
                      >
                        <td className="py-2 px-3">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-sm font-roboto"
                          >
                            {vendorName}
                          </Typography>
                        </td>
                        <td className="py-2 px-3">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-sm font-roboto"
                          >
                            {vendorPhoneNumber}
                          </Typography>
                        </td>
                        <td className="py-2 px-3">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-sm font-roboto"
                          >
                            {vendorRegion}
                          </Typography>
                        </td>
                        <td className="py-2 px-3">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-sm font-roboto"
                          >
                            {activityCategory}
                          </Typography>
                        </td>
                        <td className="py-2 px-3">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-sm font-roboto"
                          >
                            {activityTags}
                          </Typography>
                        </td>
                        <td className="py-2 px-3">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-sm font-roboto"
                          >
                            {date}
                          </Typography>
                        </td>
                        <td className="py-1 px-2 flex">
                          <div
                            onClick={() => {
                              editVendor(vendorList[index]);
                            }}
                            className="bg-gray-200 mr-2 py-0.5 px-2 rounded-md font-roboto font-medium text-gray-500 hover:cursor-pointer"
                          >
                            Edit
                          </div>
                          <div
                            onClick={() => {
                              deleteVendor(vendorName);
                            }}
                            className="bg-gray-200 py-0.5 px-2 rounded-md font-roboto font-medium text-gray-500 hover:cursor-pointer"
                          >
                            Delete
                          </div>
                        </td>
                      </tr>
                    );
                  }
                )
              )}
            </tbody>
          </table>
        </CardBody>
        <CardFooter className="flex justify-end p-1 font-roboto items-center">
          <p className="mr-2"> Rows per page</p>
          <div id="vendor-table-footer" className="mr-2">
            <Dropdown
              inline
              label={maximumRows}
              className="border"
              placement="bottom"
            >
              <Dropdown.Item
                onClick={() => {
                  changeMaxRows(10);
                }}
              >
                10
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  changeMaxRows(25);
                }}
              >
                25
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  changeMaxRows(50);
                }}
              >
                50
              </Dropdown.Item>
            </Dropdown>
          </div>
          <p>{currentPage + " of " + totalPages}</p>
          <img
            onClick={() => {
              handleFirstPage();
            }}
            className="navigators"
            src={process.env.PUBLIC_URL + "/First Page.png"}
          />
          <img
            onClick={() => {
              handlePrevPage();
            }}
            className="navigators"
            src={process.env.PUBLIC_URL + "/Chevron Left.png"}
          />
          <img
            onClick={() => {
              handleNextPage();
            }}
            className="navigators"
            src={process.env.PUBLIC_URL + "/Chevron Right.png"}
          />
          <img
            onClick={() => {
              handleLastPage();
            }}
            className="navigators"
            src={process.env.PUBLIC_URL + "/Last Page.png"}
          />
        </CardFooter>
        <VendorDeleteDialog
          isOpen={openDeleteDialog}
          refresh={() => {
            fetchTableData();
          }}
          closeDialog={() => {
            setOpenDeleteDialog(false);
            setVendorToDeleteName("");
          }}
          vendorName={vendorToDeleteName}
        />
      </Card>
      {openEditForm ? (
        <VendorEditForm
          vendorData={vendorToEditData}
          isOpen={openEditForm}
          closeDialog={() => {
            setOpenEditForm(false);
            setVendorToEditData({});
          }}
          refreshPage={() => {
            fetchTableData();
          }}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default VendorTable;
