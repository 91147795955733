import { Modal } from "flowbite-react";
const ErrorDialog = ({ isOpen, closeDialog, errorMessage }) => {
  return (
    <Modal
      id="error-dialog"
      dismissible
      show={isOpen}
      onClose={() => closeDialog()}
    >
      <Modal.Body className="flex justify-center">{errorMessage}</Modal.Body>

      <div
        className="rounded-2xl drop-shadow-lg hover:cursor-pointer bg-focus-color w-1/3 mb-2 mx-auto text-center py-2 text-white hover:bg-focus-color"
        onClick={() => {
          closeDialog();
        }}
      >
        Close
      </div>
    </Modal>
  );
};

export default ErrorDialog;
