import { db } from "../firebase";

export const fetchBookings = async () => {
  const bookingDoc = await db.collection("bookings").get();
  let allBookings = [];

  const bookingData =  bookingDoc.docs.map((a) => {
    const data = a.id;
    return data;
  });



  for (var booking of bookingData) {
   let currentBooking;
    const activityDoc = await db
      .collection("bookings")
      .doc(booking)
      .collection("bookedUsers")
      .get();
    // eslint-disable-next-line array-callback-return

    activityDoc.docs.map((a) => {
      const data = a.data();
      currentBooking = data;
     // allBookings.push(data);
    });



    if(currentBooking ) {
      const userDoc = await db
      .collection("users")
      .doc(currentBooking.userId)
      .get();
  
  
      if (userDoc.exists) {
        const user = userDoc.data();
        currentBooking = {...currentBooking,
          fullName: user.firstName + ' ' + user.lastName,
        }
      }

      allBookings.push(currentBooking);

    }
  

  }


  allBookings.sort((a, b) => {
    return (
     b.bookingTime.toDate() -
     a.bookingTime.toDate()
    );
  })

  return allBookings;
};
