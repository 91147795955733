import { Modal, Spinner } from "flowbite-react";
const LoadingDialog = ({ isOpen, closeDialog }) => {
  return (
    <Modal
      id="loading"
      show={isOpen}
      onClose={() => closeDialog()}
      className="focus:outline-none p-0"
    >
      <Modal.Body className="flex justify-center focus:outline-none p-4">
        <Spinner
          className="align-middle fill-focus-color"
          aria-label="Loading"
          size="xl"
        />
      </Modal.Body>
    </Modal>
  );
};

export default LoadingDialog;
