import React, { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Typography,
  CardFooter,
} from "@material-tailwind/react";
import {
  MagnifyingGlassIcon,
  EllipsisVerticalIcon,
} from "@heroicons/react/24/outline";
import { Dropdown } from "flowbite-react";
import LoadingDialog from "../common/loading";
import "./User.css";
import { fetchUsers } from "../../services/users";
import * as XLSX from "xlsx";

const UserTable = () => {
  const [loading, setLoading] = useState(true);
  const [allUsers, setAllUsers] = useState([]);
  const [userList, setUserList] = useState([]);
  const [maximumRows, setMaximumRows] = useState(25);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchInput, setSearchInput] = useState("");

  const fetchTableData = async () => {
    setLoading(true);
    const list = await fetchUsers();

    const copyList = [...list];
    if (list.length != 0) {
      setCurrentPage(1);
      setTotalPages(Math.floor((list.length - 1) / maximumRows + 1));
    } else {
      setCurrentPage(0);
      setTotalPages(0);
    }

    setUserList(copyList.slice(0, maximumRows));
    setAllUsers(list);
    setLoading(false);
  };

  useEffect(() => {
    fetchTableData();
  }, []);

  useEffect(() => {
    const filteredUsers = allUsers.filter((user) =>
      Object.values(user).some((value) =>
        value.toString().toLowerCase().includes(searchInput.toLowerCase())
      )
    );

    setTotalPages(Math.floor((filteredUsers.length - 1) / maximumRows) + 1);
    setUserList(filteredUsers.slice(0, maximumRows));
  }, [searchInput, allUsers, maximumRows]);

  const changeMaxRows = (rows) => {
    const copyList = [...allUsers];
    if (allUsers.length == 0) {
      setCurrentPage(0);
      setTotalPages(0);
    } else {
      setCurrentPage(1);
      setTotalPages(Math.floor((allUsers.length - 1) / rows + 1));
    }
    setUserList(copyList.splice(0, rows));
    setMaximumRows(rows);
  };

  const handleFirstPage = () => {
    if (allUsers.length == 0) {
      setCurrentPage(0);
    } else {
      setCurrentPage(1);
    }
    const copyList = [...allUsers];
    setUserList(copyList.splice(0, maximumRows));
  };

  const handleLastPage = () => {
    if (allUsers.length == 0) {
      setCurrentPage(0);
    } else {
      setCurrentPage(totalPages);
    }
    const startIdx = (totalPages - 1) * maximumRows;
    const copyList = [...allUsers];
    setUserList(copyList.splice(startIdx, maximumRows));
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      const startIdx = currentPage * maximumRows;
      const copyList = [...allUsers];
      setUserList(copyList.splice(startIdx, maximumRows));
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      const startIdx = (currentPage - 2) * maximumRows;
      const copyList = [...allUsers];
      setUserList(copyList.splice(startIdx, maximumRows));
      setCurrentPage(currentPage - 1);
    }
  };

  const TABLE_HEAD = [
    "User's Name",
    "User Email",
    "Date of Birth",
    "Phone Number",
    "Subscription",
    "Purchase Date",
    "Expiry Date",
    "Status",
    "Credits Left",
  ];

  return (
    <Card className="w-full overflow-scroll border-solid border">
      <CardHeader
        floated={false}
        shadow={false}
        className="rounded-xl p-2 m-0 flex justify-between items-center "
      >
        <LoadingDialog
          isOpen={loading}
          closeDialog={() => {
            setLoading(false);
          }}
        />
        <div className="md:w-72 ml-2 w-60 relative">
          <input
            autoComplete="off"
            type="text"
            name="search"
            placeholder="Search users"
            className="pl-12 px-2 w-full border-none bg-gray-100 focus:ring-0 rounded-2xl py-2 text-gray-700 text-xs"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
          />
          <MagnifyingGlassIcon
            className="absolute inset-y-0 left-0 pl-3
                    flex items-center  
                    pointer-events-none h-8 w-8 text-gray-400"
          />
        </div>
      </CardHeader>
      <CardBody className="overflow-x-scroll p-0 m-0 max-h-screen">
        <table className="w-full min-w-max table-auto text-left m-0">
          <thead className="">
            <tr>
              {TABLE_HEAD.map((head) => (
                <th
                  key={head}
                  className="border-y border-gray-200 bg-gray-50 py-2 px-3"
                >
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="leading-none text-sm font-roboto font-semibold"
                  >
                    {head}
                  </Typography>
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="overflow-y-scroll">
            {userList.length == 0 ? (
              <tr>
                <td colSpan={7} className="text-center py-2 px-3 border-y">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal text-sm font-roboto"
                  >
                    You haven't received any users yet.
                  </Typography>
                </td>
              </tr>
            ) : (
              userList.map(
                (
                  {
                    firstName,
                    lastName,
                    email,
                    dob,
                    phoneNo,
                    creditsSub,
                    subscription,
                  },
                  index
                ) => {
                  const isOdd = index % 2 == 1;
                  const status = subscription.status;
                  const productId = subscription.productId;
                  const subExpiryDate = subscription.expiryDate;
                  const subPurchaseDate = subscription.purchaseDate;

                  const purchaseTimeStamp = subPurchaseDate.toDate();
                  const formattedHours =
                    purchaseTimeStamp.getHours() < 10
                      ? `0${purchaseTimeStamp.getHours()}`
                      : purchaseTimeStamp.getHours();
                  const formattedMinutes =
                    purchaseTimeStamp.getMinutes() < 10
                      ? `0${purchaseTimeStamp.getMinutes()}`
                      : purchaseTimeStamp.getMinutes();
                  const purchaseTime = `${formattedHours}:${formattedMinutes}`;
                  const purchaseDate = `${purchaseTimeStamp.getDate()}/${
                    purchaseTimeStamp.getMonth() + 1
                  }/${purchaseTimeStamp.getFullYear()}`;

                  const expiryTimeStamp = subExpiryDate.toDate();
                  const formattedHoursExp =
                    expiryTimeStamp.getHours() < 10
                      ? `0${expiryTimeStamp.getHours()}`
                      : expiryTimeStamp.getHours();
                  const formattedMinutesExp =
                    expiryTimeStamp.getMinutes() < 10
                      ? `0${expiryTimeStamp.getMinutes()}`
                      : expiryTimeStamp.getMinutes();
                  const expiryTime = `${formattedHoursExp}:${formattedMinutesExp}`;
                  const expiryDate = `${expiryTimeStamp.getDate()}/${
                    expiryTimeStamp.getMonth() + 1
                  }/${expiryTimeStamp.getFullYear()}`;

                  return (
                    <tr
                      key={index}
                      className={`border-y ${isOdd ? "bg-gray-50" : ""} `}
                    >
                      <td className="py-2 px-3">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal text-sm font-roboto"
                        >
                          {firstName} {lastName}
                        </Typography>
                      </td>
                      <td className="py-2 px-3">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal text-sm font-roboto"
                        >
                          {email}
                        </Typography>
                      </td>

                      <td className="py-2 px-3">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal text-sm font-roboto"
                        >
                          {dob}
                        </Typography>
                      </td>
                      <td className="py-2 px-3">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal text-sm font-roboto"
                        >
                          {phoneNo}
                        </Typography>
                      </td>

                      <td className="py-2 px-3">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal text-sm font-roboto"
                        >
                          {subscription.iapSource === "appstore" && (
                            <i class="ri-apple-fill"></i>
                          )}

                          {subscription.iapSource === "google_play" && (
                            <i class="ri-android-fill"></i>
                          )}

                          {productId}
                        </Typography>
                      </td>
                      <td className="py-2 px-3">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal text-sm font-roboto"
                        >
                          {purchaseDate} {purchaseTime}
                        </Typography>
                      </td>
                      <td className="py-2 px-3">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal text-sm font-roboto"
                        >
                          {expiryDate} {expiryTime}
                        </Typography>
                      </td>
                      <td className="py-2 px-3">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal text-sm font-roboto"
                        >
                          {status}
                        </Typography>
                      </td>
                      <td className="py-2 px-3">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal text-sm font-roboto"
                        >
                          {creditsSub > 500 ?  500 : creditsSub}
                        </Typography>
                      </td>
                    </tr>
                  );
                }
              )
            )}
          </tbody>
        </table>
      </CardBody>
      <CardFooter className="flex justify-end p-1 font-roboto items-center">
        <p className="mr-2"> Rows per page</p>
        <div id="vendor-table-footer" className="mr-2">
          <Dropdown
            inline
            label={maximumRows}
            className="border"
            placement="bottom"
          >
            <Dropdown.Item
              onClick={() => {
                changeMaxRows(10);
              }}
            >
              10
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                changeMaxRows(25);
              }}
            >
              25
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                changeMaxRows(50);
              }}
            >
              50
            </Dropdown.Item>
          </Dropdown>
        </div>
        <p>{currentPage + " of " + totalPages}</p>
        <img
          onClick={() => {
            handleFirstPage();
          }}
          className="navigators"
          src={process.env.PUBLIC_URL + "/First Page.png"}
        />
        <img
          onClick={() => {
            handlePrevPage();
          }}
          className="navigators"
          src={process.env.PUBLIC_URL + "/Chevron Left.png"}
        />
        <img
          onClick={() => {
            handleNextPage();
          }}
          className="navigators"
          src={process.env.PUBLIC_URL + "/Chevron Right.png"}
        />
        <img
          onClick={() => {
            handleLastPage();
          }}
          className="navigators"
          src={process.env.PUBLIC_URL + "/Last Page.png"}
        />
      </CardFooter>
    </Card>
  );
};

export default UserTable;
