import { Outlet, NavLink, useLocation } from "react-router-dom";
import { Navbar } from "flowbite-react";
import { useAuth } from "../context/AuthContext";

const NavigationBar = () => {
  const { userData, signOut } = useAuth();
  const { pathname } = useLocation();

  const handleSignOut = async () => {
    await signOut();
    window.location.reload();
  };

  return (
    <>
      <Navbar fluid className="bg-button-color fixed top-0 left-0 w-full z-50">
        <Navbar.Brand href="https://www.tryvetribe.com">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/tryve-app-3c95e.appspot.com/o/tryve_white_no_text.png?alt=media&token=d094d8c3-e21f-4f5a-891d-228a65cdfe37&_gl=1*1cqu1r6*_ga*NjY4MTE0NTU2LjE2ODgyMzUzMTM.*_ga_CW55HF8NVT*MTY5NzYwNzQ2OC4xMDMuMS4xNjk3NjEwNTkxLjQ5LjAuMA.."
            className="mr-3 h-8 sm:h-10"
            alt="Tryve Logo"
          />
        </Navbar.Brand>
        <div className="flex md:order-2 items-center justify-center">
          <p className="text-white font-bold text-base hidden md:block ">
            {userData.firstName + " " + userData.lastName}
          </p>
          <img
            onClick={handleSignOut}
            className="ml-6 h-6 sm:h-8 hover:cursor-pointer hidden md:block "
            src={process.env.PUBLIC_URL + "/signOut.png"}
          ></img>
          <Navbar.Toggle className="text-white hover:bg-transparent focus:ring-00 h-6 sm:h-6" />
        </div>
        <Navbar.Collapse>
          <NavLink
            className={({ isActive }) => {
              let fontStyle =
                isActive || pathname == "/"
                  ? "font-semibold bg-primary-color md:bg-transparent text-black md:text-white"
                  : "font-normal";
              return "nav-link-class " + fontStyle;
            }}
            to="/vendor"
          >
            Vendors
          </NavLink>
          <NavLink
            className={({ isActive }) => {
              let fontStyle = isActive
                ? "font-semibold bg-primary-color md:bg-transparent text-black md:text-white"
                : "font-normal";
              return "nav-link-class " + fontStyle;
            }}
            to="/activities"
          >
            Activities
          </NavLink>
          <NavLink
            className={({ isActive }) => {
              let fontStyle = isActive
                ? "font-semibold bg-primary-color md:bg-transparent text-black md:text-white"
                : "font-normal";
              return "nav-link-class " + fontStyle;
            }}
            to="/users"
          >
            Users
          </NavLink>
          <NavLink
            className={({ isActive }) => {
              let fontStyle = isActive
                ? "font-semibold bg-primary-color md:bg-transparent text-black md:text-white"
                : "font-normal";
              return "nav-link-class " + fontStyle;
            }}
            to="/bookings"
          >
            Bookings
          </NavLink>
         
        </Navbar.Collapse>
      </Navbar>
      <Outlet />
    </>
  );
};

export default NavigationBar;
