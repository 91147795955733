import SignInPage from "./components/Auth/signIn";
import ProtectedRoute from "./components/ProtectedRoute";
import NavigationBar from "./components/Navbar";
import VendorPage from "./components/Vendors/VendorPage";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import BookingPage from "./components/Bookings/BookingPage";
import ActivitiesPage from "./components/Activities/ActivitiesPage";
import UserPage from "./components/Users/UserPage";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route exact path="/" element={<ProtectedRoute />}>
            <Route path="/" element={<NavigationBar />}>
              <Route index element={<VendorPage />} />
              <Route exact path="/vendor" element={<VendorPage />}></Route>
              <Route
                exact
                path="/activities"
                element={<ActivitiesPage />}
              ></Route>
              <Route exact path="/bookings" element={<BookingPage />}></Route>
              <Route exact path="/users" element={<UserPage />}></Route>
            </Route>
          </Route>
          <Route exact path="/signIn" element={<SignInPage />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
