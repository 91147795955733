import React, { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Typography,
  CardFooter,
} from "@material-tailwind/react";
import { MagnifyingGlassIcon, EllipsisVerticalIcon } from "@heroicons/react/24/outline";
import { Dropdown } from "flowbite-react";
import LoadingDialog from "../common/loading";
import "./Bookings.css";
import { fetchBookings } from "../../services/booking";
import * as XLSX from 'xlsx'

const BookingTable = () => {
  const [loading, setLoading] = useState(true);
  const [allBookings, setAllBookings] = useState([]);
  const [bookingList, setBookingList] = useState([]);
  const [maximumRows, setMaximumRows] = useState(25);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchInput, setSearchInput] = useState('');

  const fetchTableData = async () => {
    setLoading(true);
    const list = await fetchBookings();
    const copyList = [...list];
    if (list.length != 0) {
      setCurrentPage(1);
      setTotalPages(Math.floor((list.length - 1) / maximumRows + 1));
    } else {
      setCurrentPage(0);
      setTotalPages(0);
    }

    setBookingList(copyList.slice(0, maximumRows));
    setAllBookings(list);
    setLoading(false);
  };

  useEffect(() => {
    fetchTableData();
  }, []);

  useEffect(() => {
    const filteredBookings = allBookings.filter((booking) =>
      Object.values(booking).some((value) =>
        value.toString().toLowerCase().includes(searchInput.toLowerCase())
      )
    );
    
    setTotalPages(Math.floor((filteredBookings.length - 1) / maximumRows) + 1);
    setBookingList(filteredBookings.slice(0, maximumRows));
  }, [searchInput, allBookings, maximumRows]);

  const changeMaxRows = (rows) => {
    const copyList = [...allBookings];
    if (allBookings.length == 0) {
      setCurrentPage(0);
      setTotalPages(0);
    } else {
      setCurrentPage(1);
      setTotalPages(Math.floor((allBookings.length - 1) / rows + 1));
    }
    setBookingList(copyList.splice(0, rows));
    setMaximumRows(rows);
  };

  const handleFirstPage = () => {
    if (allBookings.length == 0) {
      setCurrentPage(0);
    } else {
      setCurrentPage(1);
    }
    const copyList = [...allBookings];
    setBookingList(copyList.splice(0, maximumRows));
  };

  const handleLastPage = () => {
    if (allBookings.length == 0) {
      setCurrentPage(0);
    } else {
      setCurrentPage(totalPages);
    }
    const startIdx = (totalPages - 1) * maximumRows;
    const copyList = [...allBookings];
    setBookingList(copyList.splice(startIdx, maximumRows));
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      const startIdx = currentPage * maximumRows;
      const copyList = [...allBookings];
      setBookingList(copyList.splice(startIdx, maximumRows));
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      const startIdx = (currentPage - 2) * maximumRows;
      const copyList = [...allBookings];
      setBookingList(copyList.splice(startIdx, maximumRows));
      setCurrentPage(currentPage - 1);
    }
  };

  const TABLE_HEAD = [
    "User Name",
    "Vendor",
    "Activity Name",
    "Activity Date",
    "Activity Time",
    "Credits Paid",
    "Booking Date",
    "User's Email",

  ];

  const exportToExcel = () => {

    // Define the data to export
    const dataToExport = [
      TABLE_HEAD, // Header row
      ...bookingList.map(({
        email, fullName, vendorName, activityName, activityDateAndTime, creditsPaid, bookingTime
      }) => {
        const activityTimestamp = activityDateAndTime.toDate();
        const formattedHours = activityTimestamp.getHours() < 10 ? `0${activityTimestamp.getHours()}` : activityTimestamp.getHours();
        const formattedMinutes = activityTimestamp.getMinutes() < 10 ? `0${activityTimestamp.getMinutes()}` : activityTimestamp.getMinutes();
        const activityTime =  `${formattedHours}:${formattedMinutes}`;
       return [
          fullName, vendorName, activityName, activityTimestamp, activityTime, creditsPaid, bookingTime.toDate(), email
      ]
    })
    ];


    // Create a new workbook and add the data to it
    const ws = XLSX.utils.aoa_to_sheet(dataToExport);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Bookings");

    // Save the file with a name
    XLSX.writeFile(wb, "bookings.xlsx");
  };

  return (
    <Card className="w-full overflow-scroll border-solid border">
      <CardHeader
        floated={false}
        shadow={false}
        className="rounded-xl p-2 m-0 flex justify-between items-center "
      >
        <LoadingDialog
          isOpen={loading}
          closeDialog={() => {
            setLoading(false);
          }}
        />
        <div className="md:w-72 ml-2 w-60 relative">
          <input
            autoComplete="off"
            type="text"
            name="search"
            placeholder="Search bookings"
            className="pl-12 px-2 w-full border-none bg-gray-100 focus:ring-0 rounded-2xl py-2 text-gray-700 text-xs"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
          />
          <MagnifyingGlassIcon
            className="absolute inset-y-0 left-0 pl-3
                    flex items-center  
                    pointer-events-none h-8 w-8 text-gray-400"
          />
        </div>
        <button
          onClick={exportToExcel}
          className="bg-[#B78160]  text-white  py-2 px-4 rounded-full ml-2"
        >
          Export to Excel
        </button>
      </CardHeader>
      <CardBody className="overflow-x-scroll p-0 m-0 max-h-screen">
        <table className="w-full min-w-max table-auto text-left m-0">
          <thead className="">
            <tr>
              {TABLE_HEAD.map((head) => (
                <th
                  key={head}
                  className="border-y border-gray-200 bg-gray-50 py-2 px-3"
                >
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="leading-none text-sm font-roboto font-semibold"
                  >
                    {head}
                  </Typography>
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="overflow-y-scroll">
            {bookingList.length == 0 ? (
              <tr>
                <td colSpan={7} className="text-center py-2 px-3 border-y">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal text-sm font-roboto"
                  >
                    You haven't received any bookings yet.
                  </Typography>
                </td>
              </tr>
            ) : (
              bookingList.map(
                (
                  {
                    email,
                    fullName,
                    vendorName,
                    activityName,
                    activityDateAndTime,
                    creditsPaid,
                    bookingTime
                  },
                  index
                ) => {
                  const isOdd = index % 2 == 1;
                  const bookingTimestamp = bookingTime.toDate();
                  const bookingDate = `${bookingTimestamp.getDate()}/${
                    bookingTimestamp.getMonth() + 1
                  }/${bookingTimestamp.getFullYear()}`;

                  const activityTimestamp = activityDateAndTime.toDate();
                  const formattedHours = activityTimestamp.getHours() < 10 ? `0${activityTimestamp.getHours()}` : activityTimestamp.getHours();
                  const formattedMinutes = activityTimestamp.getMinutes() < 10 ? `0${activityTimestamp.getMinutes()}` : activityTimestamp.getMinutes();
                  const activityTime =  `${formattedHours}:${formattedMinutes}`;
                  const activityDate = `${activityTimestamp.getDate()}/${
                    activityTimestamp.getMonth() + 1
                  }/${activityTimestamp.getFullYear()}`;
                  return (
                    <tr
                      key={index}
                      className={`border-y ${isOdd ? "bg-gray-50" : ""} `}
                    >
                      
                      <td className="py-2 px-3">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal text-sm font-roboto"
                        >
                          {fullName || 'DELETED USER'}
                        </Typography>
                      </td>
                      <td className="py-2 px-3">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal text-sm font-roboto"
                        >
                          {vendorName}
                        </Typography>
                      </td>
                      <td className="py-2 px-3">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal text-sm font-roboto"
                        >
                          {activityName}
                        </Typography>
                      </td>
                      <td className="py-2 px-3">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal text-sm font-roboto"
                        >
                          {activityDate}
                        </Typography>
                      </td>
                      <td className="py-2 px-3">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal text-sm font-roboto"
                        >
                          {activityTime}
                        </Typography>
                      </td>
                      <td className="py-2 px-3">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal text-sm font-roboto"
                        >
                          {creditsPaid}
                        </Typography>
                      </td>
                      <td className="py-2 px-3">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal text-sm font-roboto"
                        >
                          {bookingDate}
                        </Typography>
                      </td>
                      <td className="py-2 px-3">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal text-sm font-roboto"
                        >
                          {email}
                        </Typography>
                      </td>
                    </tr>
                  );
                }
              )
            )}
          </tbody>
        </table>
      </CardBody>
      <CardFooter className="flex justify-end p-1 font-roboto items-center">
        <p className="mr-2"> Rows per page</p>
        <div id="vendor-table-footer" className="mr-2">
          <Dropdown
            inline
            label={maximumRows}
            className="border"
            placement="bottom"
          >
            <Dropdown.Item
              onClick={() => {
                changeMaxRows(10);
              }}
            >
              10
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                changeMaxRows(25);
              }}
            >
              25
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                changeMaxRows(50);
              }}
            >
              50
            </Dropdown.Item>
          </Dropdown>
        </div>
        <p>{currentPage + " of " + totalPages}</p>
        <img
          onClick={() => {
            handleFirstPage();
          }}
          className="navigators"
          src={process.env.PUBLIC_URL + "/First Page.png"}
        />
        <img
          onClick={() => {
            handlePrevPage();
          }}
          className="navigators"
          src={process.env.PUBLIC_URL + "/Chevron Left.png"}
        />
        <img
          onClick={() => {
            handleNextPage();
          }}
          className="navigators"
          src={process.env.PUBLIC_URL + "/Chevron Right.png"}
        />
        <img
          onClick={() => {
            handleLastPage();
          }}
          className="navigators"
          src={process.env.PUBLIC_URL + "/Last Page.png"}
        />
      </CardFooter>
    </Card>
  );
};

export default BookingTable;