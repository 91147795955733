import { PlusIcon } from "@heroicons/react/24/solid";
import VendorTable from "./VendorTable";
import VendorForm from "./VendorAddForm";
import { useState } from "react";

const VendorPage = () => {
  const [addVendor, setAddVendor] = useState(false);
  const [refresh, setRefresh] = useState(true);

  const refreshPage = () => {
    setRefresh(!refresh);
  };

  return (
    <div className="mt-20 flex flex-col items-center">
      <p className="m-0 p-0 font-bold text-4xl">Vendors</p>
      <div className="mt-12 w-full md:w-9/12 p-2 md:p-0 flex-1">
        <div
          onClick={() => {
            setAddVendor(true);
          }}
          className="bg-focus-color rounded-2xl hover:cursor-pointer flex w-32 md:w-36 py-2 justify-center items-center mb-3"
        >
          <PlusIcon className="md:w-5 md:h-5 w-4 h-4 text-white mr-2" />
          <p className="text-white md:text-sm text-xs text-center md:font-medium font-normal">
            New Vendor
          </p>
        </div>
        <VendorTable refresh={refresh} />
      </div>

      <VendorForm
        isOpen={addVendor}
        closeDialog={() => {
          setAddVendor(false);
        }}
        refreshPage={() => refreshPage()}
      />
    </div>
  );
};

export default VendorPage;
