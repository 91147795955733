import { PlusIcon } from "@heroicons/react/24/solid";
import BookingTable from "./BookingTable";

const BookingPage = () => {
  return (
    <div className="mt-20 flex flex-col items-center">
      <p className="m-0 p-0 font-bold text-4xl">Bookings</p>
      <div className="mt-12 w-full md:w-9/12 p-2 md:p-0 flex-1">
        <BookingTable />
      </div>
    </div>
  );
};

export default BookingPage;
