import { db } from "../firebase";

export const fetchVendors = async () => {
  const vendorDoc = await db.collection("vendors").get();
  const vendorsData = vendorDoc.docs.map((a) => {
    const data = a.data();
    return data;
  });
  
  vendorsData.sort((a, b) => {
    return (
     b.createdAt.toDate() -
     a.createdAt.toDate()
    );
  })
  return vendorsData;
};

export const addVendor = async (vendorData) => {
  const vendorName = vendorData.vendorName;
  return await db.collection("vendors").doc(vendorName).set(vendorData);
};

export const deleteVendor = async (vendorName) => {
  return await db.collection("vendors").doc(vendorName).delete();
};

export const updateVendor = async (vendorData) => {
  const vendorName = vendorData.vendorName;
  return await db.collection("vendors").doc(vendorName).update(vendorData);
};
