import React, { useContext, useState, useEffect } from "react";
import { auth, db } from "../firebase";

const AuthContext = React.createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState();
  const [userData, setUserData] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  const signIn = async (email, password) => {
    setError("");
    const userDoc = await db
      .collection("users")
      .where("emailLowerCase", "==", email.toLowerCase())
      .get();

    var userDataDoc = userDoc.docs.map((a) => {
      const data = a.data();
      return data;
    });

    if (userDataDoc.length === 0) {
      setError("This email address is not registered.");
    } else if (userDataDoc.length === 1) {
      try {
        let isAuthorized = userDataDoc[0].isAdmin === true;
        if (isAuthorized) {
          await auth.signInWithEmailAndPassword(email, password);
        } else {
          setError("You are not able to login to the platform.");
        }
      } catch (e) {
        switch (e.code) {
          case "auth/user-not-found":
            setError("This email address is not registered.");
            break;
          case "auth/wrong-password":
            setError("Incorrect password. Try Again!");
            break;
          case "auth/too-many-requests":
            setError("Too many auth requests. Try again later!");
            break;
          default:
            setError(e.message);
            break;
        }
      }
    }
  };

  const signOut = () => {
    return auth.signOut();
  };

  const resetPassword = async (email) => {
    return await auth.sendPasswordResetEmail(email);
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        const userDoc = await db.collection("users").doc(user.uid).get();
        setUserData(userDoc.data());
        setCurrentUser(user);
      }

      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    signIn,
    signOut,
    resetPassword,
    error,
    userData,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
