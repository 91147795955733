import { db } from "../firebase";

export const fetchUsers = async () => {
  const userDoc = await db.collection("users").get();
  const usersData = userDoc.docs.map((a) => {
    const data = a.data();

    return { ...data, id: a.id };
  });

  let allPurchases = [];
  let newUsers = [];
  for (var user of usersData) {
    let currentPurchase;

    const purchaseDoc = await db
      .collection("purchases")
      .where("userId", "==", user.id)
      .get();
    // eslint-disable-next-line array-callback-return

    purchaseDoc.docs.map((a) => {
      const data = a.data();
      currentPurchase = data;

      if (currentPurchase) {
        allPurchases.push(currentPurchase);
      }
     
    });

    let mostRecentPurchase;
    if (allPurchases) {
       mostRecentPurchase = allPurchases.reduce((a, b) =>
      comparePurchaseDates(a, b) > 0 ? a : b
    );
    }
 
    newUsers.push({...user, subscription: mostRecentPurchase ? mostRecentPurchase : null})
  }

  newUsers.sort((a, b) => {
    return (
     b.subscription.purchaseDate.toDate() -
     a.subscription.purchaseDate.toDate()
    );
  })

  return newUsers;

};

const comparePurchaseDates = (a, b) => {
  return (
    new Date(b.purchaseDate) -
    new Date(a.purchaseDate)
  );
};
