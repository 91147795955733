import LoadingDialog from "../common/loading";
import { Modal } from "flowbite-react";
import { useState } from "react";
import { deleteVendor } from "../../services/vendor";
import "./Vendors.css";

const VendorDeleteDialog = ({ isOpen, closeDialog, vendorName, refresh }) => {
  const [loading, setLoading] = useState(false);

  const handleDelete = async () => {
    setLoading(true);
    await deleteVendor(vendorName);
    setLoading(false);
    refresh();
    closeDialog();
  };
  return (
    <Modal
      id="delete-vendor"
      show={isOpen}
      size="3xl"
      onClose={() => closeDialog()}
      className="focus:outline-none p-0"
    >
      <Modal.Header>Confirm Delete</Modal.Header>
      <Modal.Body className="flex flex-col justify-center items-center focus:outline-none p-4">
        Are you sure you want to delete {vendorName}?
        <div
          onClick={handleDelete}
          className="bg-focus-color flex justify-center my-4 font-semibold text-white rounded-2xl hover:cursor-pointer w-60 py-2 mb-3"
        >
          Confirm & Delete
        </div>
      </Modal.Body>
      <LoadingDialog
        isOpen={loading}
        closeDialog={() => {
          setLoading(false);
        }}
      />
    </Modal>
  );
};
export default VendorDeleteDialog;
