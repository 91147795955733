import { useAuth } from "../../context/AuthContext";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LoadingDialog from "../common/loading";
import ErrorDialog from "../common/errorDialog";

const SignInPage = () => {
  const { signIn, resetPassword, currentUser, error } = useAuth();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showAuthError, setShowAuthError] = useState(false);

  const [resetEmail, setResetEmail] = useState("");
  const [resetEmailError, setResetEmailError] = useState(false);
  const [resetEmailSent, setResetEmailSent] = useState();
  const [showResetForm, setShowResetForm] = useState(false);

  const validateEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  const handleEmailChange = (e) => {
    const { value } = e.target;
    if (!validateEmail(value)) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
    setEmail(value);
  };

  const handleResetEmailChange = (e) => {
    const { value } = e.target;
    if (!validateEmail(value)) {
      setEmailError(true);
    } else {
      setResetEmailError(false);
    }
    setResetEmail(value);
  };

  const handlePasswordChange = (e) => {
    const { value } = e.target;
    if (value.length === 0) {
      setPasswordError(true);
    } else {
      setPasswordError(false);
    }
    setPassword(value);
  };

  useEffect(() => {
    if (error) {
      setShowAuthError(true);
    }
  }, [error]);

  useEffect(() => {
    if (currentUser) {
      navigate("/");
    }
  }, [currentUser]);


  const handleLogin = async () => {
    if (email.length == 0 || password.length == 0) {
      if (email.length == 0) {
        setEmailError(true);
      }

      if (password.length == 0) {
        setPasswordError(true);
      }

      return;
    }

    if (!emailError && !passwordError) {
      setLoading(true);
      await signIn(email, password);
      setLoading(false);
    }
  };

  const handleResetPassword = () => {
    resetPassword(resetEmail)
      .then(() => {
        // Password reset email sent successfully
        setResetEmailSent(true);
    
      })
      .catch((error) => {
        setResetEmailSent(false);
      });
  };

  return (
    <div className="w-full h-screen flex flex-col">
      <div className="w-full flex justify-between px-8 py-3 bg-button-color m-0">
        <img
          className="w-9 h-9"
          src="https://firebasestorage.googleapis.com/v0/b/tryve-app-3c95e.appspot.com/o/tryve_white_no_text.png?alt=media&token=d094d8c3-e21f-4f5a-891d-228a65cdfe37&_gl=1*1cqu1r6*_ga*NjY4MTE0NTU2LjE2ODgyMzUzMTM.*_ga_CW55HF8NVT*MTY5NzYwNzQ2OC4xMDMuMS4xNjk3NjEwNTkxLjQ5LjAuMA.."
        />
      </div>
      <LoadingDialog
        isOpen={loading}
        closeDialog={() => {
          setLoading(false);
        }}
      />
      <ErrorDialog
        isOpen={showAuthError}
        closeDialog={() => {
          setShowAuthError(false);
        }}
        errorMessage={error}
      />

      {!showResetForm && (
        <div className="flex flex-col justify-center items-center flex-1">
          <p className="font-bold text-2xl m-0 p-0">Login to Tryve Admin</p>
          <div className="w-2/3 sm:w-2/3 lg:w-1/3">
            <p className="pt-10 font-normal text-sm">Email Address *</p>
            <input
              autoComplete="off"
              type="email"
              name="email"
              placeholder="Email"
              value={email}
              onChange={handleEmailChange}
              className={`px-2 w-full border focus:ring-0 rounded py-2 text-gray-700 mt-2 text-sm 
      ${
        emailError
          ? "border-red-300 focus:border-red-500"
          : "focus:border-focus-color"
      }
      `}
            />
            <p
              className={`font-normal text-sm text-red-400 ${
                emailError ? "block" : "hidden"
              }`}
            >
              Enter valid email address
            </p>
          </div>
          <div className="mt-6 w-2/3 sm:w-2/3 lg:w-1/3">
            <p className="font-normal text-sm">Password *</p>
            <input
              type="password"
              name="password"
              value={password}
              onChange={handlePasswordChange}
              placeholder="Password"
              className={`px-2 w-full border focus:ring-0 rounded py-2 text-gray-700 mt-2 text-sm ${
                passwordError
                  ? "border-red-300 focus:border-red-500"
                  : "focus:border-focus-color"
              }`}
            />
            <p
              className={`font-normal text-sm text-red-400 ${
                passwordError ? "block" : "hidden"
              }`}
            >
              Password field can't be empty
            </p>
            <p className="mt-2.5 font-normal text-sm text-end mr-2.5">
              <a
                className="hover:cursor-pointer"
                onClick={() => {
                  setShowResetForm(true);
                }}
              >
                Forget Password ?
              </a>
            </p>
          </div>
          <div
            onClick={handleLogin}
            className="mt-10 w-2/3 sm:w-2/3 lg:w-1/3 py-2 bg-focus-color rounded-2xl drop-shadow-lg hover:cursor-pointer"
          >
            <p className="text-white font-semibold text-sm text-center">
              Sign In
            </p>
          </div>
        </div>
      )}

      {showResetForm && (
        <div className="flex flex-col justify-center items-center flex-1">
          <p className="font-bold text-2xl m-0 p-0">Reset your password</p>
          <div className="w-2/3 sm:w-2/3 lg:w-1/3">
            <p className="pt-10 font-normal text-sm">Email Address *</p>
            <input
              autoComplete="off"
              type="resetEmail"
              name="resetEmail"
              placeholder="Email"
              value={resetEmail}
              onChange={handleResetEmailChange}
              className={`px-2 w-full border focus:ring-0 rounded py-2 text-gray-700 mt-2 text-sm 
         ${
           resetEmailError
             ? "border-red-300 focus:border-red-500"
             : "focus:border-focus-color"
         }
         `}
            />
            <p
              className={`font-normal text-sm text-red-400 ${
                resetEmailError ? "block" : "hidden"
              }`}
            >
              Enter valid email address
            </p>
          </div>

          <div
            onClick={handleResetPassword}
            className="mt-10 w-2/3 sm:w-2/3 lg:w-1/3 py-2 bg-focus-color rounded-2xl drop-shadow-lg hover:cursor-pointer"
          >
            <p className="text-white font-semibold text-sm text-center">
              Reset Password
            </p>
          </div>
          {resetEmailSent && 
            <p>Reset password instruction have been sent to your email address</p>
          }
            {resetEmailSent === false && 
            <p>An error occured, try again!</p>
          }
          <p className="mt-2.5 font-normal text-sm text-end mr-2.5">
              <a
                className="hover:cursor-pointer"
                onClick={() => {
                  setShowResetForm(false);
                }}
              >
                Return to login
              </a>
            </p>
        </div>
      )}
    </div>
  );
};

export default SignInPage;
