import { useState, useEffect } from "react";
import { PlusIcon } from "@heroicons/react/24/solid";
import ActivitiesTable from "./ActivitiesTable";
import LoadingDialog from "../common/loading";
import { fetchVendors } from "../../services/vendor";
import ActivitiesAddForm from "./ActivitiesAddForm";

const ActivitiesPage = () => {
  const [addActivity, setAddActivity] = useState(false);
  const [refresh, setRefresh] = useState(true);
  const [vendors, setVendors] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchVendorList = async () => {
    setLoading(true);
    const list = await fetchVendors();
    setVendors(list);
    setLoading(false);
  };

  useEffect(() => {
    fetchVendorList();
  }, []);

  const refreshPage = () => {
    setRefresh(!refresh);
  };

  return (
    <div className="mt-20 flex flex-col items-center">
      <p className="m-0 p-0 font-bold text-4xl">Activities</p>
      <div className="mt-12 w-full md:w-9/12 p-2 md:p-0 flex-1">
        <div
          onClick={() => {
            setAddActivity(true);
          }}
          className="bg-focus-color rounded-2xl hover:cursor-pointer flex w-32 md:w-36 py-2 justify-center items-center mb-3"
        >
          <PlusIcon className="md:w-5 md:h-5 w-4 h-4 text-white mr-2" />
          <p className="text-white md:text-sm text-xs text-center md:font-medium font-normal">
            New Activity
          </p>
        </div>
        <ActivitiesTable vendors={vendors} refresh={refresh} />
      </div>
      <LoadingDialog
        isOpen={loading}
        closeDialog={() => {
          setLoading(false);
        }}
      />
      {addActivity ? (
        <ActivitiesAddForm
          isOpen={addActivity}
          closeDialog={() => {
            setAddActivity(false);
          }}
          vendors={vendors}
          refreshPage={refreshPage}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default ActivitiesPage;
