import { Modal, Dropdown } from "flowbite-react";
import "./Vendors.css";
import { useState, useEffect, useRef } from "react";
import LoadingDialog from "../common/loading";
import { storage } from "../../firebase";
import { addVendor } from "../../services/vendor";
import { ArrowLeftIcon } from "@heroicons/react/24/solid";
import { Timestamp } from 'firebase/firestore';

const VendorForm = ({ isOpen, closeDialog, refreshPage }) => {
  const [region, setRegion] = useState("Select a region from the list");
  const [category, setCategory] = useState("Select a category from the list");
  const [northValue, setNorthValue] = useState(false);
  const [southValue, setSouthValue] = useState(false);
  const [eastValue, setEastValue] = useState(false);
  const [westValue, setWestValue] = useState(false);
  const [centerValue, setCenterValue] = useState(false);
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [website, setWebsite] = useState("");
  const [description, setDescription] = useState("");
  const [tags, setTags] = useState("");
  const [image, setImage] = useState("");
  const [nameError, setNameError] = useState(false);
  const [addressError, setAddressError] = useState(false);
  const [regionError, setRegionError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const [categoryError, setCategoryError] = useState(false);
  const [tagsError, setTagsError] = useState(false);
  const [imageError, setImageError] = useState(false);
  const [initial, setInitial] = useState(true);
  const [showFirstPage, setShowFirstPage] = useState(true);
  const [loading, setLoading] = useState(false);

  const imageInputRef = useRef(null);

  const reset = () => {
    setRegion("Select a region from the list");
    setCategory("Select a category from the list");
    setNorthValue(false);
    setEastValue(false);
    setWestValue(false);
    setSouthValue(false);
    setName("");
    setAddress("");
    setPhone("");
    setEmail("");
    setWebsite("");
    setDescription("");
    setTags("");
    setImage("");
    setNameError(false);
    setAddressError(false);
    setRegionError(false);
    setDescriptionError(false);
    setCategoryError(false);
    setTagsError(false);
    setImageError(false);
    setInitial(true);
    setShowFirstPage(true);
  };

  useEffect(() => {
    if (initial) {
      imageInputRef.current = null;
    }
    if (
      !eastValue &&
      !northValue &&
      !southValue &&
      !westValue &&
      !centerValue
    ) {
      setRegion("Select a region from the list");
      if (!initial) {
        setRegionError(true);
      } else {
        setRegionError(false);
      }
    } else {
      setRegionError(false);
      if (centerValue) {
        setRegion("Center");
      } else if (northValue) {
        var reg = "North";
        if (eastValue) {
          reg += " East";
        } else if (westValue) {
          reg += " West";
        }

        setRegion(reg);
      } else if (southValue) {
        var reg = "South";
        if (eastValue) {
          reg += " East";
        } else if (westValue) {
          reg += " West";
        }

        setRegion(reg);
      } else if (eastValue) {
        setRegion("East");
      } else {
        setRegion("West");
      }
    }
    setInitial(false);
  }, [eastValue, northValue, southValue, westValue, centerValue]);

  const handleContinue = () => {
    if (!nameError && !addressError && !regionError) {
      if (
        name === "" ||
        address === "" ||
        region === "Select a region from the list"
      ) {
        if (name === "") {
          setNameError(true);
        }

        if (address === "") {
          setAddressError(true);
        }

        if (region === "Select a region from the list") {
          setRegionError(true);
        }
        return;
      }

      setShowFirstPage(false);
    }
  };

  const uploadImage = async () => {
    if (imageInputRef.current.files.length === 0) return;
    const time = new Date().toISOString();
    const file = imageInputRef.current.files[0];
    const fileRef = storage.ref().child(file.name + "_" + time);
    await fileRef.put(file);
    const url = await fileRef.getDownloadURL();
    return url;
  };

  const handleConfirmAndSave = async () => {
    var validated = true;

    if (category === "Select a category from the list") {
      setCategoryError(true);
      validated = false;
    }

    if (description === "") {
      setDescriptionError(true);
      validated = false;
    }

    if (tags === "") {
      setTagsError(true);
      validated = false;
    }

    if (image === "") {
      setImageError(true);
      validated = false;
    }

    if (!validated) {
      return;
    }

    setLoading(true);
    const imageUrl = await uploadImage();
    const currentDate = new Date();
    const vendorData = {
      activityCategory: category,
      activityTags: tags,
      createdAt: currentDate,
      vendorAddress: address,
      vendorDescription: description,
      vendorEmail: email,
      vendorFeatureImagePath: imageUrl,
      vendorName: name,
      vendorPhoneNumber: phone,
      vendorRegion: region,
      vendorWebsite: website,
     
    };
    await addVendor(vendorData);
    setLoading(false);
    reset();
    refreshPage();
    closeDialog();
  };

  const firstPage = () => {
    return (
      <div className="flex flex-col items-start w-1/2">
        <p className="font-normal text-sm font-rubik">Vendor's name *</p>
        <input
          autoComplete="off"
          type="text"
          value={name}
          name="name"
          onChange={(e) => {
            const { value } = e.target;
            if (value === " " || value === "") {
              setName("");
              setNameError(true);
            } else {
              setName(value);
              setNameError(false);
            }
          }}
          className={`p-2 w-11/12 border focus:ring-0 border-gray-300 rounded text-gray-700 mt-2 text-sm focus:border-focus-color`}
        />
        <p
          className={`font-normal text-sm text-red-400 ${
            nameError ? "block" : "hidden"
          }`}
        >
          Name field can't be empty
        </p>
        <p className="font-normal text-sm font-rubik mt-6">
          Vendor's Address *
        </p>
        <input
          autoComplete="off"
          type="text"
          value={address}
          name="address"
          onChange={(e) => {
            const { value } = e.target;
            if (value === " " || value === "") {
              setAddress("");
              setAddressError(true);
            } else {
              setAddress(value);
              setAddressError(false);
            }
          }}
          className={`p-2 w-11/12 border focus:ring-0 border-gray-300 rounded text-gray-700 mt-2 text-sm focus:border-focus-color`}
        />
        <p
          className={`font-normal text-sm text-red-400 ${
            addressError ? "block" : "hidden"
          }`}
        >
          Address field can't be empty
        </p>
        <p id="vendor-phone" className="font-normal text-sm font-rubik mt-6">
          Vendor’s Region * (For North East , select both North and East from
          the list)
        </p>
        <Dropdown
          dismissOnClick={false}
          inline
          id="vendor-dropdown"
          label={region}
          placement="bottom"
        >
          <Dropdown.Item
            onClick={() => {
              if (!southValue && !centerValue) {
                setNorthValue(!northValue);
              }
            }}
            className={
              southValue || centerValue ? "hover:cursor-not-allowed" : ""
            }
          >
            <input
              onChange={() => {}}
              type="checkbox"
              name="north"
              checked={northValue}
              className="appearance-none w-4 h-4 border-2 focus:ring-0 checked:bg-focus-color border-focus-color rounded-sm bg-white mr-2"
            />
            <label> North</label>
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              if (!northValue && !centerValue) {
                setSouthValue(!southValue);
              }
            }}
            className={
              northValue || centerValue ? "hover:cursor-not-allowed" : ""
            }
          >
            <input
              onChange={() => {}}
              type="checkbox"
              name="south"
              checked={southValue}
              className="appearance-none w-4 h-4 border-2 focus:ring-0 checked:bg-focus-color border-focus-color rounded-sm bg-white mr-2"
            />
            <label> South</label>
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              if (!westValue && !centerValue) {
                setEastValue(!eastValue);
              }
            }}
            className={
              westValue || centerValue ? "hover:cursor-not-allowed" : ""
            }
          >
            <input
              onChange={() => {}}
              type="checkbox"
              name="east"
              checked={eastValue}
              className="appearance-none w-4 h-4 border-2 focus:ring-0 checked:bg-focus-color border-focus-color rounded-sm bg-white mr-2"
            />
            <label> East</label>
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              if (!eastValue && !centerValue) {
                setWestValue(!westValue);
              }
            }}
            className={
              eastValue || centerValue ? "hover:cursor-not-allowed" : ""
            }
          >
            <input
              onChange={() => {}}
              checked={westValue}
              type="checkbox"
              name="west"
              className="appearance-none w-4 h-4 border-2 focus:ring-0 checked:bg-focus-color border-focus-color rounded-sm bg-white mr-2"
            />
            <label> West</label>
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              if (!eastValue && !westValue && !northValue && !southValue) {
                setCenterValue(!centerValue);
              }
            }}
            className={
              eastValue || westValue || northValue || southValue
                ? "hover:cursor-not-allowed"
                : ""
            }
          >
            <input
              onChange={() => {}}
              checked={centerValue}
              type="checkbox"
              name="Central"
              className="appearance-none w-4 h-4 border-2 focus:ring-0 checked:bg-focus-color border-focus-color rounded-sm bg-white mr-2"
            />
            <label>Central</label>
          </Dropdown.Item>
        </Dropdown>
        <p
          className={`font-normal text-sm text-red-400 ${
            regionError ? "block" : "hidden"
          }`}
        >
          Region field can't be empty
        </p>
        <p className="font-normal text-sm font-rubik mt-6">
          Vendor’s Phone Number
        </p>
        <input
          autoComplete="off"
          type="test"
          name="phone"
          value={phone}
          onChange={(e) => {
            const { value } = e.target;
            setPhone(value);
          }}
          className={`p-2 w-11/12 border focus:ring-0 border-gray-300 rounded text-gray-700 mt-2 text-sm focus:border-focus-color`}
        />
        <p className="font-normal text-sm font-rubik mt-6">
          Vendor’s Email Address
        </p>
        <input
          autoComplete="off"
          type="email"
          name="email"
          value={email}
          onChange={(e) => {
            const { value } = e.target;
            setEmail(value);
          }}
          className={`p-2 w-11/12 border focus:ring-0 border-gray-300 rounded text-gray-700 mt-2 text-sm focus:border-focus-color`}
        />
        <p className="font-normal text-sm font-rubik mt-6">Vendor’s Website</p>
        <input
          autoComplete="off"
          type="text"
          name="website"
          value={website}
          onChange={(e) => {
            const { value } = e.target;
            setWebsite(value);
          }}
          className={`p-2 w-11/12 border focus:ring-0 border-gray-300 rounded text-gray-700 mt-2 text-sm focus:border-focus-color`}
        />
        <div
          onClick={() => {
            handleContinue();
          }}
          className="w-11/12 mt-7 py-2 text-center bg-focus-color text-white text-sm font-medium rounded-2xl hover:cursor-pointer"
        >
          Continue
        </div>
      </div>
    );
  };

  const secondPage = () => {
    return (
      <div className="flex flex-col items-start w-1/2">
        <p className="font-normal text-sm font-rubik">Vendor's Description *</p>
        <textarea
          autoComplete="off"
          type="text"
          rows={5}
          value={description}
          name="description"
          onChange={(e) => {
            const { value } = e.target;
            if (value == " " || value == "") {
              setDescriptionError(true);
              setDescription("");
            } else {
              setDescriptionError(false);
              setDescription(value);
            }
          }}
          className={`p-2 w-11/12 border focus:ring-0 border-gray-300 rounded text-gray-700 mt-2 text-sm focus:border-focus-color`}
        />
        <p
          className={`font-normal text-sm text-red-400 ${
            descriptionError ? "block" : "hidden"
          }`}
        >
          Description field can't be empty
        </p>
        <p id="vendor-category" className="font-normal text-sm font-rubik mt-6">
          Vendor’s Activity Category *
        </p>
        <Dropdown inline label={category} placement="bottom">
          <Dropdown.Item
            onClick={() => {
              setCategoryError(false);
              setCategory("EXPLORE MINDFULNESS");
            }}
          >
            EXPLORE MINDFULNESS
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              setCategoryError(false);
              setCategory("RELIEVE STRESS");
            }}
          >
            RELIEVE STRESS
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              setCategoryError(false);
              setCategory("CREATE SOMETHING");
            }}
          >
            CREATE SOMETHING
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              setCategoryError(false);
              setCategory("PAMPER MYSELF");
            }}
          >
            PAMPER MYSELF
          </Dropdown.Item>
        </Dropdown>
        <p
          className={`font-normal text-sm text-red-400 ${
            categoryError ? "block" : "hidden"
          }`}
        >
          Select a Category
        </p>
        <p className="font-normal text-sm font-rubik mt-6">
          Vendor’s Tags* (Separate by commas)
        </p>
        <input
          autoComplete="off"
          type="text"
          value={tags}
          name="tags"
          onChange={(e) => {
            const { value } = e.target;
            if (value === " " || value === "") {
              setTags("");
              setTagsError(true);
            } else {
              setTags(value);
              setTagsError(false);
            }
          }}
          className={`p-2 w-11/12 border focus:ring-0 border-gray-300 rounded text-gray-700 mt-2 text-sm focus:border-focus-color`}
        />
        <p
          className={`font-normal text-sm text-red-400 ${
            tagsError ? "block" : "hidden"
          }`}
        >
          Tags field can't be empty
        </p>
        <p className="font-normal text-sm font-rubik mt-6">
          Vendor’s Photo* (Support 1 photo only )
        </p>
        <label
          className={`p-2 w-11/12 border focus:ring-0 border-gray-300 rounded text-gray-500 bg-gray-200 mt-2 text-sm `}
        >
          <input
            onChange={(e) => {
              setImageError(false);
              setImage(imageInputRef.current.files[0].name);
            }}
            ref={imageInputRef}
            accept="image/png, image/jpeg"
            type="file"
            autoComplete="off"
            placeholder="Upload jpeg or png photo"
          />
          Upload jpeg or png photo
        </label>
        <p className="text-gray-700s text-sm">{image}</p>
        <p
          className={`font-normal text-sm text-red-400 ${
            imageError ? "block" : "hidden"
          }`}
        >
          Image field can't be empty
        </p>
        <div
          onClick={() => {
            handleConfirmAndSave();
          }}
          className="w-11/12 mt-10 py-2 text-center bg-focus-color text-white text-sm font-medium rounded-2xl hover:cursor-pointer"
        >
          Confirm and Save
        </div>
      </div>
    );
  };

  return (
    <Modal
      id="vendor-form"
      size="5xl"
      show={isOpen}
      onClose={() => {
        reset();
        closeDialog();
      }}
    >
      <Modal.Header id="vendor-form-header" className="px-2 py-5 items-center">
        {showFirstPage ? (
          <></>
        ) : (
          <div
            onClick={() => {
              setShowFirstPage(true);
              setImage("");
            }}
            className="flex items-center hover:cursor-pointer"
          >
            <ArrowLeftIcon className="w-6 h-6 text-black ml-2" />
            <p className="text-sm ml-2">Back</p>
          </div>
        )}
        <div className="flex flex-col items-center flex-1">
          <div className="flex justify-center items-center w-9/12 px-5 mb-2">
            <div
              className={`w-2 h-2 rounded-full ${
                !showFirstPage ? "bg-gray-300" : "bg-focus-color"
              }`}
            />
            <div className="w-max h-1 bg-gray-100 flex-1"></div>
            <div
              className={`w-2 h-2 rounded-full ${
                !showFirstPage ? "bg-focus-color" : "bg-gray-300"
              }`}
            />
          </div>
          <div className="w-9/12 flex justify-between">
            <p
              className={`text-xs text-black ${
                !showFirstPage ? "font-light" : "font-normal"
              }`}
            >
              Step 1
            </p>
            <p
              className={`text-xs text-black ${
                !showFirstPage ? "font-normal" : "font-light"
              }`}
            >
              Step 2 and Save
            </p>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body id="vendor-form-body" className="flex flex-col items-center">
        {showFirstPage ? firstPage() : secondPage()}
      </Modal.Body>
      <LoadingDialog
        isOpen={loading}
        closeDialog={() => {
          setLoading(false);
        }}
      />
    </Modal>
  );
};

export default VendorForm;
