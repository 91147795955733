import { db } from "../firebase";

export const fetchActivities = async () => {
  const activityDoc = await db.collection("activities").get();
  const activitiesData = activityDoc.docs.map((a) => {
    const id = a.id;
    const data = a.data();
    return { ...data, id: id };
  });


  activitiesData.sort((a, b) => {
    return (
     b.modifiedAt.toDate() -
     a.modifiedAt.toDate()
    );
  })

  return activitiesData;
};

export const addActivity = async (activityData) => {
  return await db.collection("activities").doc().set(activityData);
};

export const deleteActivity = async (id) => {
  return await db.collection("activities").doc(id).delete();
};

export const updateActivity = async (id, activityData) => {
  return await db.collection("activities").doc(id).update(activityData);
};
