import { Modal, Dropdown } from "flowbite-react";
import "react-datepicker/dist/react-datepicker.css";
import "./Activities.css";
import { useState, useEffect, useRef } from "react";
import LoadingDialog from "../common/loading";
import { ArrowLeftIcon, CalendarIcon } from "@heroicons/react/24/solid";
import DatePicker from "react-datepicker";
import DatePicker2 from "react-multi-date-picker";
import { addActivity } from "../../services/activities";
import { Timestamp } from 'firebase/firestore';

const ActivitiesAddForm = ({ isOpen, closeDialog, vendors, refreshPage }) => {
  const [showFirstPage, setShowFirstPage] = useState(true);
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState(false);
  const [description, setDescription] = useState("");
  const [descriptionError, setDescriptionError] = useState(false);
  const [vendor, setVendor] = useState("Pick a vendor from the list");
  const [vendorError, setVendorError] = useState(false);
  const [slots, setSlot] = useState("");
  const [slotsError, setSlotsError] = useState(false);
  const [credits, setCredits] = useState("");
  const [creditsError, setCreditsError] = useState(false);
  const [instructor, setInstructor] = useState("");
  const [isRecurring, setIsRecurring] = useState(false);
  const [dateOfActivity, setDateOfActivity] = useState("");
  const [dateOfActivityError, setDateOfActivityError] = useState(false);
  const [activityTimes, setActivityTimes] = useState("");
  const [activityTimesError, setActivityTimesError] = useState(false);
  const [invalidActivityTimes, setInvalidActivityTimes] = useState(false);
  const [recurrenceType, setRecurrenceType] = useState(
    "Select a recurency type"
  );
  const [recurencyError, setRecurrencyError] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [startDateError, setStartDateError] = useState(false);
  const [endDate, setEndDate] = useState("");
  const [endDateError, setEndDateError] = useState("");
  const minDate = new Date();
  const [minStartDate, setMinStartDate] = useState(minDate);
  const [weekDayValue, setWeekDayValue] = useState({
    Sunday: false,
    Monday: false,
    Tuesday: false,
    Wednesday: false,
    Thursday: false,
    Friday: false,
    Saturday: false,
  });
  const [weekDaySelected, setWeekDaySelected] = useState(
    "Select one or multiple week day"
  );
  const [weekDayError, setWeekDayError] = useState(false);
  const [customValue, setCustomValue] = useState([]);
  const [customValueError, setCustomValueError] = useState(false);
  const [duration, setDuration] = useState("");
  const [durationError, setDurationError] = useState(false);
  const [loading, setLoading] = useState(false);

  const weekDayToNumber = new Map();
  weekDayToNumber.set("Sunday", 0);
  weekDayToNumber.set("Monday", 1);
  weekDayToNumber.set("Tuesday", 2);
  weekDayToNumber.set("Wednesday", 3);
  weekDayToNumber.set("Thursday", 4);
  weekDayToNumber.set("Friday", 5);
  weekDayToNumber.set("Saturday", 6);

  const handleWeekDayChange = (day) => {
    var val = [];
    var weekDayCopy = weekDayValue;
    weekDayCopy[day] = !weekDayValue[day];
    if (weekDayCopy.Sunday) {
      val.push("Sunday");
    }

    if (weekDayCopy.Monday) {
      val.push("Monday");
    }

    if (weekDayCopy.Tuesday) {
      val.push("Tuesday");
    }

    if (weekDayCopy.Wednesday) {
      val.push("Wednesday");
    }

    if (weekDayCopy.Thursday) {
      val.push("Thursday");
    }

    if (weekDayCopy.Friday) {
      val.push("Friday");
    }

    if (weekDayCopy.Saturday) {
      val.push("Saturday");
    }

    if (val.length === 0) {
      setWeekDayError(true);
      setWeekDaySelected("Select one or multiple week day");
    } else {
      setWeekDayError(false);
      setWeekDaySelected(val.join(" "));
    }
  };

  const handleContinue = () => {
    var validate = true;

    if (name === "") {
      setNameError(true);
      validate = false;
    }

    if (description === "") {
      setDescriptionError(true);
      validate = false;
    }

    if (vendor === "Pick a vendor from the list") {
      setVendorError(true);
      validate = false;
    }

    if (slots === "") {
      setSlotsError(true);
      validate = false;
    }

    if (credits === "") {
      setCreditsError(true);
      validate = false;
    }

    if (duration === "") {
      setDurationError(true);
      validate = false;
    }

    if (!validate) {
      return;
    }

    setShowFirstPage(false);
  };

  function dateComparison(a, b) {
    return a - b;
  }

  const handleSaveActivity = async () => {
    var validate = true;
    if (isRecurring) {
      if (recurrenceType === "Select a recurency type") {
        setRecurrencyError(true);
        validate = false;
      } else if (recurrenceType === "Custom") {
        if (customValue.length === 0) {
          setCustomValueError(true);
          validate = false;
        }
      } else {
        if (startDate === "") {
          setStartDateError(true);
          validate = false;
        }

        if (endDate === "") {
          setEndDateError(true);
          validate = false;
        }

        if (recurrenceType === "Weekly") {
          if (weekDaySelected === "Select one or multiple week day") {
            validate = false;
            setWeekDayError(true);
          }
        }
      }
    } else {
      if (dateOfActivity === "") {
        setDateOfActivityError(true);
        validate = false;
      }
    }

    if (activityTimes === "") {
      setActivityTimesError(true);
      validate = false;
    }

    if (invalidActivityTimes) {
      validate = false;
    }

    if (!validate) {
      return;
    }

    setLoading(true);

    var daysOfWeek = [];

    if (weekDayValue.Sunday) {
      daysOfWeek.push(7);
    }
    if (weekDayValue.Monday) {
      daysOfWeek.push(1);
    }
    if (weekDayValue.Tuesday) {
      daysOfWeek.push(2);
    }
    if (weekDayValue.Wednesday) {
      daysOfWeek.push(3);
    }
    if (weekDayValue.Thursday) {
      daysOfWeek.push(4);
    }
    if (weekDayValue.Friday) {
      daysOfWeek.push(5);
    }
    if (weekDayValue.Saturday) {
      daysOfWeek.push(6);
    }

    var customDates = [];
    for (var idx = 0; idx < customValue.length; idx++) {
      const date = customValue[idx];
      customDates.push(new Date(date.year, date.month.number - 1, date.day));
    }

    customDates.sort(dateComparison);
    const end = isRecurring
      ? recurrenceType === "Custom"
        ? customDates[customDates.length - 1]
        : endDate
      : dateOfActivity;

    const start = isRecurring
      ? recurrenceType === "Custom"
        ? customDates[0]
        : startDate
      : dateOfActivity;

    const times = activityTimes.split(",");
    var timesArray = [];
    for (var idx = 0; idx < times.length; idx++) {
      const time = times[idx].trim();
      if (time.length != 0) {
        timesArray.push(time);
      }
    }

    const activityData = {
      activityCreditCost: credits,
      activityDayOfWeek: daysOfWeek,
      activityDetails: description,
      activityEndDate: end,
      activityStartDate: start,
      activityName: name,
      activityTimes: timesArray,
      duration: duration,
      instructorName: instructor,
      recurrenceType: isRecurring ? recurrenceType : "None",
      customDates: customDates,
      slots: slots,
      vendor: vendor,
      createdAt: Timestamp.fromDate( new Date()),
      modifiedAt: Timestamp.fromDate( new Date())
    };

    await addActivity(activityData);
    setLoading(false);
    refreshPage();
    closeDialog();
  };

  function isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }

  function CustomMultipleInput({ onFocus, value }) {
    return (
      <div className="mt-3 w-11/12 relative">
        <input
          onFocus={onFocus}
          value={value}
          readOnly
          placeholder="Pick Multiple Dates"
          autoComplete="off"
          type="text"
          className="pl-12 px-2 w-full border border-gray-300 rounded focus:ring-0 py-2 text-gray-700 text-xs focus:border-gray-300"
        />
        <CalendarIcon
          className="absolute inset-y-0 left-0 pl-3
                    flex items-center  
                    pointer-events-none h-8 w-8"
        />
      </div>
    );
  }

  const handleActivityTimesInput = (e) => {
    const { value } = e.target;

    if (value === "" || value === " ") {
      setActivityTimes("");
      setActivityTimesError(true);
      setInvalidActivityTimes(false);
    } else {
      setActivityTimes(value);
      setActivityTimesError(false);

      var validate = true;
      const times = value.split(",");
      for (var idx = 0; idx < times.length; idx++) {
        const time = times[idx].trim();
        if (time.length === 0) {
          if (times.length - 1 != idx) {
            validate = false;
          }
          break;
        }

        if (time.length != 5) {
          validate = false;
          break;
        }

        if (!isNumeric(time.substring(0, 2)) || !isNumeric(time.substring(3))) {
          validate = false;
          break;
        }

        if (time[2] != ":") {
          validate = false;
          break;
        }

        try {
          const hour = parseInt(time.substring(0, 2));
          const minute = parseInt(time.substring(3));

          if (hour < 0 || hour > 23 || minute < 0 || minute >= 60) {
            validate = false;
            break;
          }
        } catch {
          validate = false;
          break;
        }
      }

      setInvalidActivityTimes(!validate);
    }
  };

  const firstPage = () => {
    return (
      <div className="flex flex-col items-start w-1/2">
        <p className="font-normal text-sm font-rubik">Activity name *</p>
        <input
          autoComplete="off"
          type="text"
          value={name}
          name="name"
          onChange={(e) => {
            const { value } = e.target;
            if (value === " " || value === "") {
              setName("");
              setNameError(true);
            } else {
              setName(value);
              setNameError(false);
            }
          }}
          className={`p-2 w-11/12 border focus:ring-0 border-gray-300 rounded text-gray-700 mt-2 text-sm focus:border-focus-color`}
        />
        <p
          className={`font-normal text-sm text-red-400 ${
            nameError ? "block" : "hidden"
          }`}
        >
          Name field can't be empty
        </p>
        <p className="font-normal text-sm font-rubik mt-6">
          Activity Description *
        </p>
        <textarea
          autoComplete="off"
          type="text"
          rows={3}
          value={description}
          name="description"
          onChange={(e) => {
            const { value } = e.target;
            if (value == " " || value == "") {
              setDescriptionError(true);
              setDescription("");
            } else {
              setDescriptionError(false);
              setDescription(value);
            }
          }}
          className={`p-2 w-11/12 border focus:ring-0 border-gray-300 rounded text-gray-700 mt-2 text-sm focus:border-focus-color`}
        />
        <p
          className={`font-normal text-sm text-red-400 ${
            descriptionError ? "block" : "hidden"
          }`}
        >
          Description field can't be empty
        </p>
        <p id="vendor-category" className="font-normal text-sm font-rubik mt-6">
          Vendor*
        </p>
        <Dropdown inline label={vendor} placement="bottom">
          {vendors.map(({ vendorName }) => {
            return (
              <Dropdown.Item
                onClick={() => {
                  setVendor(vendorName);
                  setVendorError(false);
                }}
              >
                {vendorName}
              </Dropdown.Item>
            );
          })}
        </Dropdown>
        <p
          className={`font-normal text-sm text-red-400 ${
            vendorError ? "block" : "hidden"
          }`}
        >
          Select a Vendor
        </p>
        <p className="font-normal text-sm font-rubik mt-6">
          Activity number of slots (number only) *
        </p>
        <input
          autoComplete="off"
          type="number"
          value={slots}
          name="slots"
          onChange={(e) => {
            const { value } = e.target;
            if (value === " " || value === "") {
              setSlot("");
              setSlotsError(true);
            } else {
              setSlot(value);
              setSlotsError(false);
            }
          }}
          className={`p-2 w-11/12 border focus:ring-0 border-gray-300 rounded text-gray-700 mt-2 text-sm focus:border-focus-color`}
        />
        <p
          className={`font-normal text-sm text-red-400 ${
            slotsError ? "block" : "hidden"
          }`}
        >
          Slots field can't be empty
        </p>
        <p className="font-normal text-sm font-rubik mt-6">
          Activity Credits (number only) *
        </p>
        <input
          autoComplete="off"
          type="number"
          value={credits}
          name="credots"
          onChange={(e) => {
            const { value } = e.target;
            if (value === " " || value === "") {
              setCredits("");
              setCreditsError(true);
            } else {
              setCredits(value);
              setCreditsError(false);
            }
          }}
          className={`p-2 w-11/12 border focus:ring-0 border-gray-300 rounded text-gray-700 mt-2 text-sm focus:border-focus-color`}
        />
        <p
          className={`font-normal text-sm text-red-400 ${
            creditsError ? "block" : "hidden"
          }`}
        >
          Credits field can't be empty
        </p>
        <p className="font-normal text-sm font-rubik mt-6">
          Activity Duration (In Minutes) *
        </p>
        <input
          autoComplete="off"
          type="number"
          value={duration}
          name="duration"
          onChange={(e) => {
            const { value } = e.target;
            if (value === " " || value === "") {
              setDuration("");
              setDurationError(true);
            } else {
              setDuration(value);
              setDurationError(false);
            }
          }}
          className={`p-2 w-11/12 border focus:ring-0 border-gray-300 rounded text-gray-700 mt-2 text-sm focus:border-focus-color`}
        />
        <p
          className={`font-normal text-sm text-red-400 ${
            durationError ? "block" : "hidden"
          }`}
        >
          Duration field can't be empty
        </p>
        <p className="font-normal text-sm font-rubik mt-6">Instructor Name</p>
        <input
          autoComplete="off"
          type="text"
          name="instructor"
          value={instructor}
          onChange={(e) => {
            const { value } = e.target;
            setInstructor(value);
          }}
          className={`p-2 w-11/12 border focus:ring-0 border-gray-300 rounded text-gray-700 mt-2 text-sm focus:border-focus-color`}
        />
        <div
          onClick={() => {
            handleContinue();
          }}
          className="w-11/12 mt-7 py-2 text-center bg-focus-color text-white text-sm font-medium rounded-2xl hover:cursor-pointer"
        >
          Continue
        </div>
      </div>
    );
  };

  const secondPage = () => {
    return (
      <div className="flex flex-col items-start w-1/2">
        <p className="font-normal text-sm font-rubik mb-2">
          Is this activity recurring?
        </p>
        <div className="flex items-center">
          <input
            onChange={() => {
              setIsRecurring(!isRecurring);
            }}
            type="checkbox"
            name="recurring"
            checked={isRecurring}
            className="appearance-none w-5 h-5 border-2 focus:ring-0 checked:bg-focus-color border-gray-300 rounded-sm bg-white mr-2"
          />
          <label className="font-normal text-sm font-rubik text-gray-600">
            If yes, click on the checkbox
          </label>
        </div>
        {!isRecurring ? (
          <>
            <p className="font-normal text-sm font-rubik mt-6">
              Date of activity *
            </p>
            <DatePicker
              dateFormat="dd/MM/yyyy"
              className="w-11/12 p-2"
              showIcon
              selected={dateOfActivity}
              minDate={minDate}
              wrapperClassName="date-picker"
              placeholderText="Pick a date"
              onChange={(date) => {
                setDateOfActivity(date);
                setDateOfActivityError(false);
              }}
              icon={<CalendarIcon />}
            />
            <p
              className={`font-normal text-sm text-red-400 ${
                dateOfActivityError ? "block" : "hidden"
              }`}
            >
              Date of Activity field can't be empty
            </p>
          </>
        ) : (
          <>
            <p
              id="activity-recurrence"
              className="font-normal text-sm font-rubik mt-6"
            >
              Recurency Type *
            </p>
            <Dropdown inline label={recurrenceType} placement="bottom">
              <Dropdown.Item
                onClick={() => {
                  setRecurrenceType("Daily");
                  setRecurrencyError(false);
                }}
              >
                Daily
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  setRecurrenceType("Weekly");
                  setRecurrencyError(false);
                }}
              >
                Weekly
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  setRecurrenceType("Custom");
                  setRecurrencyError(false);
                }}
              >
                Custom
              </Dropdown.Item>
            </Dropdown>
            <p
              className={`font-normal text-sm text-red-400 ${
                recurencyError ? "block" : "hidden"
              }`}
            >
              Select a Recurency
            </p>
            {recurrenceType === "Daily" || recurrenceType === "Weekly" ? (
              <>
                <p className="font-normal text-sm font-rubik mt-6">
                  Date to start the recurency *
                </p>
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  className="w-11/12 p-2"
                  showIcon
                  selected={startDate}
                  minDate={minDate}
                  maxDate={endDate}
                  wrapperClassName="date-picker"
                  placeholderText="Pick a date"
                  onChange={(date) => {
                    setStartDate(date);
                    setStartDateError(false);
                    setMinStartDate(date);
                  }}
                  icon={<CalendarIcon />}
                />
                <p
                  className={`font-normal text-sm text-red-400 ${
                    startDateError ? "block" : "hidden"
                  }`}
                >
                  Start Date of Recurency field can't be empty
                </p>
                <p className="font-normal text-sm font-rubik mt-6">
                  Date to end the recurency *
                </p>
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  className="w-11/12 p-2"
                  showIcon
                  selected={endDate}
                  minDate={minStartDate}
                  wrapperClassName="date-picker"
                  placeholderText="Pick a date"
                  onChange={(date) => {
                    setEndDateError(false);
                    setEndDate(date);
                  }}
                  icon={<CalendarIcon />}
                />
                <p
                  className={`font-normal text-sm text-red-400 ${
                    endDateError ? "block" : "hidden"
                  }`}
                >
                  End Date of Recurency field can't be empty
                </p>
                {recurrenceType === "Weekly" ? (
                  <>
                    <p
                      id="activity-weekly"
                      className="font-normal text-sm font-rubik mt-6"
                    >
                      Select days of the week *
                    </p>
                    <Dropdown
                      dismissOnClick={false}
                      inline
                      label={weekDaySelected}
                      placement="bottom"
                    >
                      {Array.from(weekDayToNumber.keys()).map((day) => (
                        <Dropdown.Item
                          onClick={() => {
                            const val = weekDayValue[day];
                            setWeekDayValue({ ...weekDayValue, [day]: !val });
                            handleWeekDayChange(day);
                          }}
                        >
                          <input
                            onChange={() => {}}
                            type="checkbox"
                            name="north"
                            checked={weekDayValue[day]}
                            className="appearance-none w-4 h-4 border-2 focus:ring-0 checked:bg-focus-color border-focus-color rounded-sm bg-white mr-2"
                          />
                          <label> {day}</label>
                        </Dropdown.Item>
                      ))}
                    </Dropdown>
                    <p
                      className={`font-normal text-sm text-red-400 ${
                        weekDayError ? "block" : "hidden"
                      }`}
                    >
                      Select atleast one weekday
                    </p>
                  </>
                ) : (
                  <></>
                )}
              </>
            ) : recurrenceType === "Custom" ? (
              <>
                <DatePicker2
                  format="DD/MM/YYYY"
                  minDate={minDate}
                  containerClassName="multi-date-picker"
                  multiple
                  value={customValue}
                  onChange={(val) => {
                    if (val.length == 0) {
                      setCustomValueError(true);
                    } else {
                      setCustomValueError(false);
                    }
                    setCustomValue(val);
                  }}
                  dateSeparator=" & "
                  render={<CustomMultipleInput />}
                  mapDays={({ date, selectedDate, isSameDate, today }) => {
                    let props = {};
                    if (isSameDate(date, today)) {
                      props.style = {
                        backgroundColor: "#2C1D14",
                      };
                    }
                    for (var idx = 0; idx < selectedDate.length; idx++) {
                      if (isSameDate(date, selectedDate[idx])) {
                        props.style = {
                          backgroundColor: "#B78160",
                        };
                        break;
                      }
                    }

                    return props;
                  }}
                />
                <p
                  className={`font-normal text-sm text-red-400 ${
                    customValueError ? "block" : "hidden"
                  }`}
                >
                  Select atleast one date
                </p>
              </>
            ) : (
              <></>
            )}
          </>
        )}
        <p className="font-normal text-sm font-rubik mt-6">
          Add active times slote in 24h format separated by a comma*
        </p>
        <input
          autoComplete="off"
          type="text"
          value={activityTimes}
          placeholder="Ex: 09:00, 14:30"
          name="activityTimes"
          onChange={handleActivityTimesInput}
          className={`p-2 w-11/12 border focus:ring-0 border-gray-300 rounded text-gray-700 mt-2 text-sm focus:border-focus-color`}
        />
        <p
          className={`font-normal text-sm text-red-400 ${
            activityTimesError ? "block" : "hidden"
          }`}
        >
          Activity Times field can't be empty
        </p>

        <p
          className={`font-normal text-sm text-red-400 ${
            invalidActivityTimes ? "block" : "hidden"
          }`}
        >
          Invalid Activity Time
        </p>
        <div
          onClick={() => {
            handleSaveActivity();
          }}
          className="w-11/12 mt-10 py-2 text-center bg-focus-color text-white text-sm font-medium rounded-2xl hover:cursor-pointer"
        >
          Save Activity
        </div>
      </div>
    );
  };

  return (
    <Modal
      id="activity-form"
      size="5xl"
      show={isOpen}
      onClose={() => {
        closeDialog();
      }}
    >
      <Modal.Header
        id="activity-form-header"
        className="px-2 py-5 items-center"
      >
        {showFirstPage ? (
          <></>
        ) : (
          <div
            onClick={() => {
              setShowFirstPage(true);
            }}
            className="flex items-center hover:cursor-pointer"
          >
            <ArrowLeftIcon className="w-6 h-6 text-black ml-2" />
            <p className="text-sm ml-2">Back</p>
          </div>
        )}
        <div className="flex flex-col items-center flex-1">
          <div className="flex justify-center items-center w-9/12 px-5 mb-2">
            <div
              className={`w-2 h-2 rounded-full ${
                !showFirstPage ? "bg-gray-300" : "bg-focus-color"
              }`}
            />
            <div className="w-max h-1 bg-gray-100 flex-1"></div>
            <div
              className={`w-2 h-2 rounded-full ${
                !showFirstPage ? "bg-focus-color" : "bg-gray-300"
              }`}
            />
          </div>
          <div className="w-9/12 flex justify-between">
            <p
              className={`text-xs text-black ${
                !showFirstPage ? "font-light" : "font-normal"
              }`}
            >
              Step 1
            </p>
            <p
              className={`text-xs text-black ${
                !showFirstPage ? "font-normal" : "font-light"
              }`}
            >
              Step 2 and Save
            </p>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body
        id="activity-form-body"
        className="flex flex-col items-center"
      >
        {showFirstPage ? firstPage() : secondPage()}
      </Modal.Body>
      {loading ? (
        <LoadingDialog
          isOpen={loading}
          closeDialog={() => {
            setLoading(false);
          }}
        />
      ) : (
        <></>
      )}
    </Modal>
  );
};

export default ActivitiesAddForm;
